@import '@launchpad-ui/tokens/dist/media-queries.css';

/* Inspired by http://tachyons.io/docs/layout/spacing/ */

/* padding */
.u-pa-0 {
  padding: 0;
}

.u-pa-xs {
  padding: 0.15625rem;
}

.u-pa-s {
  padding: 0.3125rem;
}

.u-pa-m {
  padding: 0.625rem;
}

.u-pa-l {
  padding: 1.25rem;
}

.u-pa-xl-5 {
  padding: 2.1875rem;
}

.u-pa-xl {
  padding: 2.5rem;
}

/* padding left */

.u-pl-0 {
  padding-left: 0;
}

.u-pl-xs {
  padding-left: 0.15625rem;
}

.u-pl-s {
  padding-left: 0.3125rem;
}

.u-pl-m {
  padding-left: 0.625rem;
}

.u-pl-l {
  padding-left: 1.25rem;
}

.u-pl-xl {
  padding-left: 2.5rem;
}

/* padding right */

.u-pr-0 {
  padding-right: 0;
}

.u-pr-xs {
  padding-right: 0.15625rem;
}

.u-pr-s {
  padding-right: 0.3125rem;
}

.u-pr-m {
  padding-right: 0.625rem;
}

.u-pr-l {
  padding-right: 1.25rem;
}

.u-pr-xl {
  padding-right: 2.5rem;
}

/* padding bottom */

.u-pb-xxs {
  padding-bottom: 0.0625rem;
}

.u-pb-xs {
  padding-bottom: 0.15625rem;
}

.u-pb-s {
  padding-bottom: 0.3125rem;
}

.u-pb-m {
  padding-bottom: 0.625rem;
}

.u-pb-l {
  padding-bottom: 1.25rem;
}

.u-pb-xl {
  padding-bottom: 2.5rem;
}

/* padding top */
.u-pt-0 {
  padding-top: 0;
}

.u-pt-xxs {
  padding-top: 0.0625rem;
}

.u-pt-xs {
  padding-top: 0.15625rem;
}

.u-pt-s {
  padding-top: 0.3125rem;
}

.u-pt-m {
  padding-top: 0.625rem;
}

.u-pt-mml {
  padding-top: 0.84375rem;
}

.u-pt-ml {
  padding-top: 0.9375rem;
}

.u-pt-l {
  padding-top: 1.25rem;
}

.u-ml-ns {
  margin-left: -0.3125rem;
}

.u-pt-xl {
  padding-top: 2.5rem;
}

/* padding left */
.u-pv-xs {
  padding-top: 0.15625rem;
  padding-bottom: 0.15625rem;
}

.u-pv-s {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.u-pv-m {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.u-pv-l {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.u-pv-xl {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

/* padding left and right */
.u-ph-0 {
  padding-left: 0;
  padding-right: 0;
}

.u-ph-xs {
  padding-left: 0.15625rem;
  padding-right: 0.15625rem;
}

.u-ph-s {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.u-ph-m {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.u-ph-l {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.u-ph-xl {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

/* margin */
.u-ma-auto {
  margin: auto;
}

.u-ma-xs {
  margin: 0.15625rem;
}

.u-ma-s {
  margin: 0.3125rem;
}

.u-ma-m {
  margin: 0.625rem;
}

.u-ma-l {
  margin: 1.25rem;
}

.u-ma-xl {
  margin: 2.5rem;
}

/* margin left */
.u-ml-auto {
  margin-left: auto;
}

.u-ml-0 {
  margin-left: 0;
}

.u-ml-xs {
  margin-left: 0.15625rem;
}

.u-ml-s {
  margin-left: 0.3125rem;
}

.u-ml-m {
  margin-left: 0.625rem;
}

.u-ml-l {
  margin-left: 1.25rem;
}

.u-ml-xl {
  margin-left: 2.5rem;
}

/* margin right */
.u-mr-auto {
  margin-right: auto;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mr-xs {
  margin-right: 0.15625rem;
}

.u-mr-s {
  margin-right: 0.3125rem;
}

.u-mr-m {
  margin-right: 0.625rem;
}

.u-mr-l {
  margin-right: 1.25rem;
}

.u-mr-xl {
  margin-right: 2.5rem;
}

/* margin bottom */
.u-mb-0 {
  margin-bottom: 0;
}
.u-mb-xs {
  margin-bottom: 0.15625rem;
}

.u-mb-s {
  margin-bottom: 0.3125rem;
}

.u-mb-m {
  margin-bottom: 0.625rem;
}

.u-mb-l {
  margin-bottom: 1.25rem;
}

.u-mb-xl {
  margin-bottom: 2.5rem;
}

.u-mb-n1 {
  margin-bottom: -0.625rem;
}

/* margin top */
.u-mt-0 {
  margin-top: 0;
}

.u-mt-xs {
  margin-top: 0.15625rem;
}

.u-mt-s {
  margin-top: 0.3125rem;
}

.u-mt-m {
  margin-top: 0.625rem;
}

.u-mt-l {
  margin-top: 1.25rem;
}

.u-mt-xl {
  margin-top: 2.5rem;
}

.u-mt-n2 {
  margin-top: -1.25rem;
}

.u-mt-n4 {
  margin-top: -2.5rem;
}

/* margin top and bottom */
.u-mv-xs {
  margin-top: 0.15625rem;
  margin-bottom: 0.15625rem;
}

.u-mv-s {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.u-mv-m {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.u-mv-l {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.u-mv-xl {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

/* margin left and right */

.u-mh-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-mh-xs {
  margin-left: 0.15625rem;
  margin-right: 0.15625rem;
}

.u-mh-s {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

.u-mh-m {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.u-mh-l {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.u-mh-xl {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

/* no margin */

.u-mh-0 {
  margin: 0rem 0rem 0rem 0rem !important;
  border: none !important;
}

@media (--mobile) {
  /* tiny viewport padding */
  .u-xs-pa-xs {
    padding: 0.15625rem;
  }

  .u-xs-pa-s {
    padding: 0.3125rem;
  }

  .u-xs-pa-m {
    padding: 0.625rem;
  }

  .u-xs-pa-l {
    padding: 1.25rem;
  }

  .u-xs-pa-xl {
    padding: 2.5rem;
  }

  /* tiny viewport padding left */
  .u-xs-pl-xs {
    padding-left: 0.15625rem;
  }

  .u-xs-pl-s {
    padding-left: 0.3125rem;
  }

  .u-xs-pl-m {
    padding-left: 0.625rem;
  }

  .u-xs-pl-l {
    padding-left: 1.25rem;
  }

  .u-xs-pl-xl {
    padding-left: 2.5rem;
  }

  /* tiny viewport padding right */
  .u-xs-pr-xs {
    padding-right: 0.15625rem;
  }

  .u-xs-pr-s {
    padding-right: 0.3125rem;
  }

  .u-xs-pr-m {
    padding-right: 0.625rem;
  }

  .u-xs-pr-l {
    padding-right: 1.25rem;
  }

  .u-xs-pr-xl {
    padding-right: 2.5rem;
  }

  /* tiny viewport padding bottom */
  .u-xs-pb-xs {
    padding-bottom: 0.15625rem;
  }

  .u-xs-pb-s {
    padding-bottom: 0.3125rem;
  }

  .u-xs-pb-m {
    padding-bottom: 0.625rem;
  }

  .u-xs-pb-l {
    padding-bottom: 1.25rem;
  }

  .u-xs-pb-xl {
    padding-bottom: 2.5rem;
  }

  /* tiny viewport padding top */
  .u-xs-pt-xs {
    padding-top: 0.15625rem;
  }

  .u-xs-pt-s {
    padding-top: 0.3125rem;
  }

  .u-xs-pt-m {
    padding-top: 0.625rem;
  }

  .u-xs-pt-l {
    padding-top: 1.25rem;
  }

  .u-xs-pt-xl {
    padding-top: 2.5rem;
  }

  /* tiny viewport padding top and bottom */
  .u-xs-pv-xs {
    padding-top: 0.15625rem;
    padding-bottom: 0.15625rem;
  }

  .u-xs-pv-s {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .u-xs-pv-m {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .u-xs-pv-l {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .u-xs-pv-xl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  /* tiny viewport padding left and right */
  .u-xs-ph-xs {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem;
  }

  .u-xs-ph-s {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }

  .u-xs-ph-m {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .u-xs-ph-l {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .u-xs-ph-xl {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  /* tiny viewport margin */
  .u-xs-ma-xs {
    margin: 0.15625rem;
  }

  .u-xs-ma-s {
    margin: 0.3125rem;
  }

  .u-xs-ma-m {
    margin: 0.625rem;
  }

  .u-xs-ma-l {
    margin: 1.25rem;
  }

  .u-xs-ma-xl {
    margin: 2.5rem;
  }

  /* tiny viewport margin left */
  .u-xs-ml-xs {
    margin-left: 0.15625rem;
  }

  .u-xs-ml-s {
    margin-left: 0.3125rem;
  }

  .u-xs-ml-m {
    margin-left: 0.625rem;
  }

  .u-xs-ml-l {
    margin-left: 1.25rem;
  }

  .u-xs-ml-xl {
    margin-left: 2.5rem;
  }

  /* tiny viewport margin right */
  .u-xs-mr-xs {
    margin-right: 0.15625rem;
  }

  .u-xs-mr-s {
    margin-right: 0.3125rem;
  }

  .u-xs-mr-m {
    margin-right: 0.625rem;
  }

  .u-xs-mr-l {
    margin-right: 1.25rem;
  }

  .u-xs-mr-xl {
    margin-right: 2.5rem;
  }

  /* tiny viewport margin bottom */
  .u-xs-mb-xs {
    margin-bottom: 0.15625rem;
  }

  .u-xs-mb-s {
    margin-bottom: 0.3125rem;
  }

  .u-xs-mb-m {
    margin-bottom: 0.625rem;
  }

  .u-xs-mb-l {
    margin-bottom: 1.25rem;
  }

  .u-xs-mb-xl {
    margin-bottom: 2.5rem;
  }

  /* tiny viewport margin top */
  .u-xs-mt-xs {
    margin-top: 0.15625rem;
  }

  .u-xs-mt-s {
    margin-top: 0.3125rem;
  }

  .u-xs-mt-m {
    margin-top: 0.625rem;
  }

  .u-xs-mt-l {
    margin-top: 1.25rem;
  }

  .u-xs-mt-xl {
    margin-top: 2.5rem;
  }

  /* tiny viewport margin top and bottom */
  .u-xs-mv-xs {
    margin-top: 0.15625rem;
    margin-bottom: 0.15625rem;
  }

  .u-xs-mv-s {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }

  .u-xs-mv-m {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .u-xs-mv-l {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .u-xs-mv-xl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  /* tiny viewport margin left and right */
  .u-xs-mh-xs {
    margin-left: 0.15625rem;
    margin-right: 0.15625rem;
  }

  .u-xs-mh-s {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }

  .u-xs-mh-m {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .u-xs-mh-l {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .u-xs-mh-xl {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

@media (--tablet) {
  /* small viewport padding */
  .u-sm-pa-xs {
    padding: 0.15625rem;
  }

  .u-sm-pa-s {
    padding: 0.3125rem;
  }

  .u-sm-pa-m {
    padding: 0.625rem;
  }

  .u-sm-pa-l {
    padding: 1.25rem;
  }

  .u-sm-pa-xl {
    padding: 2.5rem;
  }

  /* small viewport padding left */
  .u-sm-pl-xs {
    padding-left: 0.15625rem;
  }

  .u-sm-pl-s {
    padding-left: 0.3125rem;
  }

  .u-sm-pl-m {
    padding-left: 0.625rem;
  }

  .u-sm-pl-l {
    padding-left: 1.25rem;
  }

  .u-sm-pl-xl {
    padding-left: 2.5rem;
  }

  /* small viewport padding right */
  .u-sm-pr-xs {
    padding-right: 0.15625rem;
  }

  .u-sm-pr-s {
    padding-right: 0.3125rem;
  }

  .u-sm-pr-m {
    padding-right: 0.625rem;
  }

  .u-sm-pr-l {
    padding-right: 1.25rem;
  }

  .u-sm-pr-xl {
    padding-right: 2.5rem;
  }

  /* small viewport padding bottom */
  .u-sm-pb-xs {
    padding-bottom: 0.15625rem;
  }

  .u-sm-pb-s {
    padding-bottom: 0.3125rem;
  }

  .u-sm-pb-m {
    padding-bottom: 0.625rem;
  }

  .u-sm-pb-l {
    padding-bottom: 1.25rem;
  }

  .u-sm-pb-xl {
    padding-bottom: 2.5rem;
  }

  /* small viewport padding top */
  .u-sm-pt-xs {
    padding-top: 0.15625rem;
  }

  .u-sm-pt-s {
    padding-top: 0.3125rem;
  }

  .u-sm-pt-m {
    padding-top: 0.625rem;
  }

  .u-sm-pt-l {
    padding-top: 1.25rem;
  }

  .u-sm-pt-xl {
    padding-top: 2.5rem;
  }

  /* small viewport padding top and bottom */
  .u-sm-pv-xs {
    padding-top: 0.15625rem;
    padding-bottom: 0.15625rem;
  }

  .u-sm-pv-s {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .u-sm-pv-m {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .u-sm-pv-l {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .u-sm-pv-xl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  /* small viewport padding left and right */
  .u-sm-ph-xs {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem;
  }

  .u-sm-ph-s {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }

  .u-sm-ph-m {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .u-sm-ph-l {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .u-sm-ph-xl {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  /* small viewport margin */
  .u-sm-ma-xs {
    margin: 0.15625rem;
  }

  .u-sm-ma-s {
    margin: 0.3125rem;
  }

  .u-sm-ma-m {
    margin: 0.625rem;
  }

  .u-sm-ma-l {
    margin: 1.25rem;
  }

  .u-sm-ma-xl {
    margin: 2.5rem;
  }

  /* small viewport margin left */
  .u-sm-ml-xs {
    margin-left: 0.15625rem;
  }

  .u-sm-ml-s {
    margin-left: 0.3125rem;
  }

  .u-sm-ml-m {
    margin-left: 0.625rem;
  }

  .u-sm-ml-l {
    margin-left: 1.25rem;
  }

  .u-sm-ml-xl {
    margin-left: 2.5rem;
  }

  /* small viewport margin right */
  .u-sm-mr-xs {
    margin-right: 0.15625rem;
  }

  .u-sm-mr-s {
    margin-right: 0.3125rem;
  }

  .u-sm-mr-m {
    margin-right: 0.625rem;
  }

  .u-sm-mr-l {
    margin-right: 1.25rem;
  }

  .u-sm-mr-xl {
    margin-right: 2.5rem;
  }

  /* small viewport margin bottom */
  .u-sm-mb-xs {
    margin-bottom: 0.15625rem;
  }

  .u-sm-mb-s {
    margin-bottom: 0.3125rem;
  }

  .u-sm-mb-m {
    margin-bottom: 0.625rem;
  }

  .u-sm-mb-l {
    margin-bottom: 1.25rem;
  }

  .u-sm-mb-xl {
    margin-bottom: 2.5rem;
  }

  /* small viewport margin top */
  .u-sm-mt-xs {
    margin-top: 0.15625rem;
  }

  .u-sm-mt-s {
    margin-top: 0.3125rem;
  }

  .u-sm-mt-m {
    margin-top: 0.625rem;
  }

  .u-sm-mt-l {
    margin-top: 1.25rem;
  }

  .u-sm-mt-xl {
    margin-top: 2.5rem;
  }

  /* small viewport margin top and bottom */
  .u-sm-mv-xs {
    margin-top: 0.15625rem;
    margin-bottom: 0.15625rem;
  }

  .u-sm-mv-s {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }

  .u-sm-mv-m {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .u-sm-mv-l {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .u-sm-mv-xl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  /* small viewport margin left and right */
  .u-sm-mh-xs {
    margin-left: 0.15625rem;
    margin-right: 0.15625rem;
  }

  .u-sm-mh-s {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }

  .u-sm-mh-m {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .u-sm-mh-l {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .u-sm-mh-xl {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

@media (--desktop) {
  /* medium viewport padding */
  .u-md-pa-xs {
    padding: 0.15625rem;
  }

  .u-md-pa-s {
    padding: 0.3125rem;
  }

  .u-md-pa-m {
    padding: 0.625rem;
  }

  .u-md-pa-l {
    padding: 1.25rem;
  }

  .u-md-pa-xl {
    padding: 2.5rem;
  }

  /* medium viewport padding left */
  .u-md-pl-xs {
    padding-left: 0.15625rem;
  }

  .u-md-pl-s {
    padding-left: 0.3125rem;
  }

  .u-md-pl-m {
    padding-left: 0.625rem;
  }

  .u-md-pl-l {
    padding-left: 1.25rem;
  }

  .u-md-pl-xl {
    padding-left: 2.5rem;
  }

  /* medium viewport padding right */
  .u-md-pr-xs {
    padding-right: 0.15625rem;
  }

  .u-md-pr-s {
    padding-right: 0.3125rem;
  }

  .u-md-pr-m {
    padding-right: 0.625rem;
  }

  .u-md-pr-l {
    padding-right: 1.25rem;
  }

  .u-md-pr-xl {
    padding-right: 2.5rem;
  }

  /* medium viewport padding bottom */
  .u-md-pb-xs {
    padding-bottom: 0.15625rem;
  }

  .u-md-pb-s {
    padding-bottom: 0.3125rem;
  }

  .u-md-pb-m {
    padding-bottom: 0.625rem;
  }

  .u-md-pb-l {
    padding-bottom: 1.25rem;
  }

  .u-md-pb-xl {
    padding-bottom: 2.5rem;
  }

  /* medium viewport padding top */
  .u-md-pt-xs {
    padding-top: 0.15625rem;
  }

  .u-md-pt-s {
    padding-top: 0.3125rem;
  }

  .u-md-pt-m {
    padding-top: 0.625rem;
  }

  .u-md-pt-l {
    padding-top: 1.25rem;
  }

  .u-md-pt-xl {
    padding-top: 2.5rem;
  }

  /* medium viewport padding top and bottom */
  .u-md-pv-xs {
    padding-top: 0.15625rem;
    padding-bottom: 0.15625rem;
  }

  .u-md-pv-s {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .u-md-pv-m {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .u-md-pv-l {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .u-md-pv-xl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  /* medium viewport padding left and right */
  .u-md-ph-xs {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem;
  }

  .u-md-ph-s {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }

  .u-md-ph-m {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .u-md-ph-l {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .u-md-ph-xl {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  /* medium viewport margin */
  .u-md-ma-xs {
    margin: 0.15625rem;
  }

  .u-md-ma-s {
    margin: 0.3125rem;
  }

  .u-md-ma-m {
    margin: 0.625rem;
  }

  .u-md-ma-l {
    margin: 1.25rem;
  }

  .u-md-ma-xl {
    margin: 2.5rem;
  }

  /* medium viewport margin left */
  .u-md-ml-xs {
    margin-left: 0.15625rem;
  }

  .u-md-ml-s {
    margin-left: 0.3125rem;
  }

  .u-md-ml-m {
    margin-left: 0.625rem;
  }

  .u-md-ml-l {
    margin-left: 1.25rem;
  }

  .u-md-ml-xl {
    margin-left: 2.5rem;
  }

  /* medium viewport margin right */
  .u-md-mr-xs {
    margin-right: 0.15625rem;
  }

  .u-md-mr-s {
    margin-right: 0.3125rem;
  }

  .u-md-mr-m {
    margin-right: 0.625rem;
  }

  .u-md-mr-l {
    margin-right: 1.25rem;
  }

  .u-md-mr-xl {
    margin-right: 2.5rem;
  }

  /* medium viewport margin bottom */
  .u-md-mb-xs {
    margin-bottom: 0.15625rem;
  }

  .u-md-mb-s {
    margin-bottom: 0.3125rem;
  }

  .u-md-mb-m {
    margin-bottom: 0.625rem;
  }

  .u-md-mb-l {
    margin-bottom: 1.25rem;
  }

  .u-md-mb-xl {
    margin-bottom: 2.5rem;
  }

  /* medium viewport margin top */
  .u-md-mt-xs {
    margin-top: 0.15625rem;
  }

  .u-md-mt-s {
    margin-top: 0.3125rem;
  }

  .u-md-mt-m {
    margin-top: 0.625rem;
  }

  .u-md-mt-l {
    margin-top: 1.25rem;
  }

  .u-md-mt-xl {
    margin-top: 2.5rem;
  }

  /* medium viewport margin top and bottom */
  .u-md-mv-xs {
    margin-top: 0.15625rem;
    margin-bottom: 0.15625rem;
  }

  .u-md-mv-s {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }

  .u-md-mv-m {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .u-md-mv-l {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .u-md-mv-xl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  /* medium viewport margin left and right */
  .u-md-mh-xs {
    margin-left: 0.15625rem;
    margin-right: 0.15625rem;
  }

  .u-md-mh-s {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }

  .u-md-mh-m {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .u-md-mh-l {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .u-md-mh-xl {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

@media (--wide) {
  /* large viewport padding */
  .u-lg-pa-xs {
    padding: 0.15625rem;
  }

  .u-lg-pa-s {
    padding: 0.3125rem;
  }

  .u-lg-pa-m {
    padding: 0.625rem;
  }

  .u-lg-pa-l {
    padding: 1.25rem;
  }

  .u-lg-pa-xl {
    padding: 2.5rem;
  }

  /* large viewport padding left */
  .u-lg-pl-xs {
    padding-left: 0.15625rem;
  }

  .u-lg-pl-s {
    padding-left: 0.3125rem;
  }

  .u-lg-pl-m {
    padding-left: 0.625rem;
  }

  .u-lg-pl-l {
    padding-left: 1.25rem;
  }

  .u-lg-pl-xl {
    padding-left: 2.5rem;
  }

  /* large viewport padding right */
  .u-lg-pr-xs {
    padding-right: 0.15625rem;
  }

  .u-lg-pr-s {
    padding-right: 0.3125rem;
  }

  .u-lg-pr-m {
    padding-right: 0.625rem;
  }

  .u-lg-pr-l {
    padding-right: 1.25rem;
  }

  .u-lg-pr-xl {
    padding-right: 2.5rem;
  }

  /* large viewport padding bottom */
  .u-lg-pb-xs {
    padding-bottom: 0.15625rem;
  }

  .u-lg-pb-s {
    padding-bottom: 0.3125rem;
  }

  .u-lg-pb-m {
    padding-bottom: 0.625rem;
  }

  .u-lg-pb-l {
    padding-bottom: 1.25rem;
  }

  .u-lg-pb-xl {
    padding-bottom: 2.5rem;
  }

  /* large viewport padding top */
  .u-lg-pt-xs {
    padding-top: 0.15625rem;
  }

  .u-lg-pt-s {
    padding-top: 0.3125rem;
  }

  .u-lg-pt-m {
    padding-top: 0.625rem;
  }

  .u-lg-pt-l {
    padding-top: 1.25rem;
  }

  .u-lg-pt-xl {
    padding-top: 2.5rem;
  }

  /* large viewport padding top and bottom */
  .u-lg-pv-xs {
    padding-top: 0.15625rem;
    padding-bottom: 0.15625rem;
  }

  .u-lg-pv-s {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .u-lg-pv-m {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .u-lg-pv-l {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .u-lg-pv-xl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  /* large viewport padding left and right */
  .u-lg-ph-xs {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem;
  }

  .u-lg-ph-s {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }

  .u-lg-ph-m {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .u-lg-ph-l {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .u-lg-ph-xl {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  /* large viewport margin */
  .u-lg-ma-xs {
    margin: 0.15625rem;
  }

  .u-lg-ma-s {
    margin: 0.3125rem;
  }

  .u-lg-ma-m {
    margin: 0.625rem;
  }

  .u-lg-ma-l {
    margin: 1.25rem;
  }

  .u-lg-ma-xl {
    margin: 2.5rem;
  }

  /* large viewport margin left */
  .u-lg-ml-xs {
    margin-left: 0.15625rem;
  }

  .u-lg-ml-s {
    margin-left: 0.3125rem;
  }

  .u-lg-ml-m {
    margin-left: 0.625rem;
  }

  .u-lg-ml-l {
    margin-left: 1.25rem;
  }

  .u-lg-ml-xl {
    margin-left: 2.5rem;
  }

  /* large viewport margin right */
  .u-lg-mr-xs {
    margin-right: 0.15625rem;
  }

  .u-lg-mr-s {
    margin-right: 0.3125rem;
  }

  .u-lg-mr-m {
    margin-right: 0.625rem;
  }

  .u-lg-mr-l {
    margin-right: 1.25rem;
  }

  .u-lg-mr-xl {
    margin-right: 2.5rem;
  }

  /* large viewport margin bottom */
  .u-lg-mb-xs {
    margin-bottom: 0.15625rem;
  }

  .u-lg-mb-s {
    margin-bottom: 0.3125rem;
  }

  .u-lg-mb-m {
    margin-bottom: 0.625rem;
  }

  .u-lg-mb-l {
    margin-bottom: 1.25rem;
  }

  .u-lg-mb-xl {
    margin-bottom: 2.5rem;
  }

  /* large viewport margin top */
  .u-lg-mt-xs {
    margin-top: 0.15625rem;
  }

  .u-lg-mt-s {
    margin-top: 0.3125rem;
  }

  .u-lg-mt-m {
    margin-top: 0.625rem;
  }

  .u-lg-mt-l {
    margin-top: 1.25rem;
  }

  .u-lg-mt-xl {
    margin-top: 2.5rem;
  }

  /* large viewport margin top and bottom */
  .u-lg-mv-xs {
    margin-top: 0.15625rem;
    margin-bottom: 0.15625rem;
  }

  .u-lg-mv-s {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }

  .u-lg-mv-m {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .u-lg-mv-l {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .u-lg-mv-xl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  /* large viewport margin left and right */
  .u-lg-mh-xs {
    margin-left: 0.15625rem;
    margin-right: 0.15625rem;
  }

  .u-lg-mh-s {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }

  .u-lg-mh-m {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .u-lg-mh-l {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .u-lg-mh-xl {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}
