@import '@launchpad-ui/tokens/dist/media-queries.css';

:root {
  --border-width: 1px;
}

/* Inspired by http://tachyons.io/docs/layout/borders/ */

.u-b--lp-color-gray-700 {
  border-color: var(--lp-color-gray-700);
}

.u-b--ui-secondary {
  border-color: var(--lp-color-border-ui-secondary);
}

.u-b--gray-wash {
  border-color: var(--lp-color-bg-ui-secondary);
}

.u-b--lp-color-white-950 {
  border-color: var(--lp-color-white-950);
}

.u-b--lp-color-gray-500 {
  border-color: var(--lp-color-gray-500);
}

.u-b--ui-primary {
  border-color: var(--lp-color-border-ui-primary);
}

.u-b--error {
  border: 1px solid var(--lp-color-border-feedback-error);
}

.u-ba {
  border-style: solid;
  border-width: var(--border-width);
}

.u-bt {
  border-top-style: solid;
  border-top-width: var(--border-width);
}

.u-br {
  border-right-style: solid;
  border-right-width: var(--border-width);
}

.u-bb {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width);
}

.u-bl {
  border-left-style: solid;
  border-left-width: var(--border-width);
}

.u-bn {
  border-style: solid;
  border-width: 0;
}

.u-hidden-border {
  border: hidden;
}

.u-border-none {
  border: none !important;
  border-radius: none !important;
}

@media (--mobile) {
  .u-xs-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-xs-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-xs-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-xs-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-xs-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-xs-bn {
    border-style: solid;
    border-width: 0;
  }
}

@media (--tablet) {
  .u-sm-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-sm-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-sm-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-sm-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-sm-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-sm-bn {
    border-style: solid;
    border-width: 0;
  }
}

@media (--desktop) {
  .u-md-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-md-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-md-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-md-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-md-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-md-bn {
    border-style: solid;
    border-width: 0;
  }
}

@media (--wide) {
  .u-lg-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-lg-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-lg-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-lg-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-lg-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-lg-bn {
    border-style: solid;
    border-width: 0;
  }
}
